import { loadStripe } from '@stripe/stripe-js';
import { Services } from '@/store/actions/api';
import { Client } from '@/services';
import { ErrorType } from '@/constants';
import HasPlans from '@/modules/service-plan/mixins/HasPlans';
import { STRIPE_CREATE_OPTIONS } from '../../../plugins/stripe';
import { jsonToFormData } from '../../../utils/http-common';
import RecaptchaForm from '../../../components/mixins/RecaptchaForm';
import { FormRules, preSubscriptionRedirect, roleCheckLoop } from '../tools/helpers';
import { PAYMENT_TYPES } from '../tools/stripeConf';
import { captureException } from '@sentry/vue';

const StripeCard = () => import('./StripeCard.vue');
const AddressForm = () => import('./AddressForm.vue');
const PlanSelector = () => import('@/modules/service-plan/components/PlanSelector.vue');

/** @implements { stripe: Stripe } */
export default {
  name: 'StripeForm',

  mixins: [HasPlans, RecaptchaForm],

  components: {
    PlanSelector,
    StripeCard,
    AddressForm,
  },

  props: {
    plan: {
      type: String,
      required: true,
    },
    elementsOptions: {
      type: Object,
      default: () => ({}),
    },
    planSelectorEnabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    this.publishableKey = STRIPE_CREATE_OPTIONS.pk;

    return {
      rules: FormRules,

      initialized: false,
      loading: false,
      sent: false,
      valid: true,
      paymentInfo: null,
      paymentLink: null,
      formErrors: [],
      cardError: null,

      alertContent: null,

      selectedPlan: null,

      stripe: null,
      elements: null,
      cardElement: null,

      name: this.$store.state.user.profile.DisplayName ?? null,
      email: this.$store.state.user.profile.Email ?? null,

      address: {},
    };
  },

  computed: {
    currentPlan: {
      get() {
        return this.isDataReady ? this.registerServicePlans.find((plan) => plan.level === this.plan) : null;
      },
      set(plan) {
        if (plan) {
          preSubscriptionRedirect(this, plan.level, true);
        }
      },
    },
  },

  methods: {
    validate() {
      this.$refs.form.validate();
    },

    reset() {
      this.$refs.form.reset();
    },

    resetValidation() {
      this.$refs.form.resetValidation();
    },

    showError({ code, message }) {
      this.paymentInfo = '';
      this.formErrors.push({ message, color: 'error' });
      this.$emit('error', code, message);
    },

    cleanErrors() {
      this.paymentInfo = '';
      this.formErrors = [];
    },

    async onPay(e) {
      this.$log.debug('LOCO', e);
      this.validate();

      if (this.valid) {
        await this.onSubmit(e);
      }
    },

    async onSubmit(e) {
      this.$log.debug('LOCO', e);
      if (!this.valid) {
        return;
      }

      e.preventDefault();

      this.loading = true;
      this.cleanErrors();
      this.$emit('loading', true);

      try {
        this.paymentInfo = 'Connecting to Stripe...';
        const createPaymentMethodPayload = {
          type: PAYMENT_TYPES.CARD,
          card: this.cardElement,
          billing_details: {
            name: this.name,
            email: this.email,
            address: this.address,
          },
        };

        const { error, paymentMethod } = await this.stripe.createPaymentMethod(createPaymentMethodPayload);
        if (error) {
          this.showError(error);
        }

        // 4242424242424242
        // 4065935433090231
        // 4000000000000341 Attaching this card to a Customer object succeeds, but attempts to
        //                  charge the customer fail.

        const params = {
          service: Services.Subscription.key,
          method: Services.Subscription.methods.NewTransaction.key,
          gateway: 'stripe',
        };

        let payload = {
          // http://195.82.188.122:3000/?service=SubscriptionService&method=NewTransaction&gateway=stripe
          recurringPlan: this.selectedPlan,
          // userID: this.$store.state.user.profile.ID,
          payment_method_id: paymentMethod.id,
          name: this.name,
          email: this.email,
          address: paymentMethod.billing_details.address,
        };

        if (this.enableRecaptcha) {
          payload.RC_ResponseToken = await this.recaptchaToken('payment');
        }

        const formDataPayload = jsonToFormData(payload);

        this.$log.debug('LOCO', Array.from(formDataPayload));

        this.paymentInfo = '$vuetify.paymentForm.notif.sendingRequest';

        // TODO: handle properly errors
        const { data: newTransactionResponse } = await Client({
          method: 'post',
          params,
          data: formDataPayload,
          errorHandler: this.errorHandler,
        });

        const { externalID, subscription } = newTransactionResponse.response;

        this.$log.debug('LOCO', subscription);

        switch (subscription.status) {
          case 'active':
            {
              const result = await roleCheckLoop(this, this.currentPlan.level);
              this.$emit('submit', result);
            }
            break;
          case 'incomplete':
            {
              // eslint-disable-next-line camelcase
              let { status, client_secret } = subscription.latest_invoice.payment_intent;
              if (status === 'requires_action') {
                this.paymentInfo = 'Confirming payment...';
                const { error, paymentIntent } = await this.stripe.confirmCardPayment(client_secret);

                if (error) {
                  this.showError(error);
                } else {
                  this.$log.debug('LOCO', paymentIntent);
                  const result = await roleCheckLoop(this, this.currentPlan.level);
                  this.$emit('submit', result);
                }
              }

              if (status === 'requires_payment_method') {
                this.showError({ code: 'Payment failed', message: 'We are sorry, the was an error processing your payment. Please try again with a different payment method.' });
              }
            }
            break;
          default:
          // TODO: ?throw Error
        }
      } catch (error) {
        captureException(error);
        this.$emit('submit', false);
      } finally {
        this.loading = false;
        this.$emit('loading', false);
        this.sent = true;
      }
    },

    subPlanLabel(plan) {
      let suffix = '';
      if (plan.discount) {
        suffix = `<span class="red--text"> - save $${plan.discount}</span>`;
      }
      return `${plan.paymentTitle} ($${plan.amount}${suffix})`;
    },

    /**
     * Should return false if we don't want to go into core error handler
     *
     * @param {Error} err
     * @returns boolean
     */
    errorHandler(err) {
      if (err) {
        let { config, request, response, isAxiosError, message } = err;

        if (isAxiosError && response?.data.type === ErrorType.SERVICE_ERROR) {
          if (response.data?.message === 'Remote returned HTTP ERROR code 400 when calling NewTransaction') {
            this.showError({ message: '$vuetify.paymentForm.notif.error_request' });
            return false;
          }
        }
      }
      return 'Stripe Form Error';
    },
  },

  async mounted() {
    const stripeOptions = {
      // stripeAccount: this.stripeAccount,
      // apiVersion: this.apiVersion,
      // locale: this.locale,
    };

    const createOptions = {
      // classes: this.classes,
      // style,
      // value: this.value,
      // hidePostalCode: this.hidePostalCode,
      // hideIcon: this.hideIcon,
      // disabled: this.disabled,
    };

    try {
      this.stripe = await loadStripe(this.publishableKey);

      this.elements = this.stripe.elements(this.elementsOptions);

      this.initialized = true;
    } catch (error) {
      captureException(error);
    }
  },
};
