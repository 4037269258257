// Style Object documentation here: https://stripe.com/docs/js/appendix/style

const PAYMENT_TYPES = Object.freeze({
  CARD: 'card',
});

const style = {
  base: {
    color: 'black',
    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
    fontSmoothing: 'antialiased',
    fontSize: '14px',
    '::placeholder': {
      color: '#aab7c4',
    },
    lineHeight: '32px',
  },
  invalid: {
    color: '#fa755a',
    iconColor: '#fa755a',
  },
};

export { style, PAYMENT_TYPES };
